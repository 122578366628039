import { useState } from "react";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Button,
  Paper,
  Skeleton,
  Drawer,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import dayjs from "dayjs";
import { HEADER_BAR_HEIGHT } from "../HeaderBar";
import { primaryBlue } from "../../style";

const offsetModalTop = {
  style: {
    top: `${HEADER_BAR_HEIGHT}vh`,
    marginBottom: "300px",
    width: "100%",
  },
};

const StatsStoreGroupCard = ({
  isLoading = false,
  isCurrentDay = true,
  selectedDate = "",
  storeGroupName = "",
  totalStores = "",
  openStores = "",
  storeGroupMetrics = [],
  individualStoreMetrics = [],
}) => {
  const [state, setState] = useState({
    isDrawerOpen: false,
  });

  return (
    <>
      <Grid
        item
        xs={12}
        sm={9}
        md={6}
        style={{ padding: 10, paddingTop: 5, paddingBottom: 5 }}
      >
        <Grid
          container
          component={Paper}
          elevation={8}
          style={{
            padding: 4,
            background: "#f1f3f4",
            marginBottom: 10,
            width: "100%",
          }}
        >
          <Grid item xs={12} style={{ padding: 4, display: "flex" }}>
            <Grid item xs={8}>
              <Typography
                sx={{ fontSize: 14, fontWeight: "bold" }}
                color="#1e232e"
              >
                {storeGroupName}
              </Typography>
              <Typography sx={{ fontSize: 12 }} color="#1e232e">
                {`${totalStores} Total Stores`}
              </Typography>
              {isCurrentDay && (
                <Typography sx={{ fontSize: 12 }}>
                  <span style={{ color: "green" }}>{`${openStores} Open`}</span>
                  {` | `}
                  <span style={{ color: "#c52134" }}>{`${
                    totalStores - openStores
                  } Closed`}</span>
                </Typography>
              )}
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <IconButton
                style={{ padding: 0 }}
                onClick={() =>
                  setState((prev) => ({ ...prev, isDrawerOpen: true }))
                }
              >
                <LaunchIcon style={{ color: "#03a66e" }} />
              </IconButton>
              {Boolean(isCurrentDay && openStores && !isLoading) && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Typography
                    sx={{ fontSize: 12, marginRight: 1, color: "green" }}
                  >
                    Real-Time
                  </Typography>
                  <Skeleton
                    variant="circular"
                    width={10}
                    height={10}
                    style={{ backgroundColor: "green" }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          {Boolean(!isCurrentDay || !openStores) && (
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 12, textAlign: "right" }}>
                {dayjs(selectedDate).format("dddd, MMM D, YYYY")}
              </Typography>
            </Grid>
          )}

          {storeGroupMetrics.map(({ gridWidth, label, value, color }, key) => (
            <Grid item xs={gridWidth} style={{ padding: 4 }} key={key}>
              <Box
                style={{
                  padding: 8,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#fff",
                }}
              >
                <Typography style={{ fontSize: "1rem" }}>{label}</Typography>
                {isLoading && (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={75}
                    height={19}
                  />
                )}
                {!isLoading && (
                  <Typography style={{ fontSize: "1rem", color }}>
                    {value}
                  </Typography>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={state.isDrawerOpen}
        ModalProps={offsetModalTop}
        BackdropProps={offsetModalTop}
        PaperProps={offsetModalTop}
        style={{ zIndex: 0 }}
        variant="temporary"
      >
        <Grid
          container
          style={{
            width: "100%",
            marginBottom: 250,
          }}
        >
          <Grid container>
            <Button
              color="primary"
              onClick={() =>
                setState((prev) => ({ ...prev, isDrawerOpen: false }))
              }
              style={{ padding: 0, margin: 10 }}
              startIcon={<ArrowBackIosNewRoundedIcon />}
            >
              Back
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#f1f3f4",
              textAlign: "center",
              paddingTop: 8,
              borderBottom: "1px solid white",
            }}
          >
            <Typography
              sx={{ fontSize: 14, fontWeight: "bold" }}
              color="#1e232e"
            >
              {storeGroupName}
            </Typography>
            <Typography sx={{ fontSize: 12 }} color="#1e232e">
              {`${totalStores} Total Stores`}
            </Typography>
            {isCurrentDay && (
              <Typography sx={{ fontSize: 12 }}>
                <span style={{ color: "green" }}>{`${openStores} Open`}</span>
                {` | `}
                <span style={{ color: "#c52134" }}>{`${
                  totalStores - openStores
                } Closed`}</span>
              </Typography>
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: Boolean(isCurrentDay && openStores)
                  ? "space-between"
                  : "center",
                alignItems: "center",
                padding: 8,
              }}
            >
              {Boolean(isCurrentDay && openStores) && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Typography
                    sx={{ fontSize: 12, marginRight: 1, color: "green" }}
                  >
                    Real-Time
                  </Typography>
                  <Skeleton
                    variant="circular"
                    width={10}
                    height={10}
                    style={{ backgroundColor: "green" }}
                  />
                </Box>
              )}

              <Typography sx={{ fontSize: 12, textAlign: "right" }}>
                {dayjs(selectedDate).format("dddd, MMM D, YYYY")}
              </Typography>
            </Box>
          </Grid>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "#263452",
                      color: "white",
                      width: 100,
                    }}
                  >
                    Store
                  </TableCell>
                  {storeGroupMetrics.map(({ label }, i) => (
                    <TableCell
                      key={i}
                      style={{
                        zIndex: 0,
                        minWidth: 80,
                        textAlign: "center",
                        backgroundColor: "#263452",
                        color: "white",
                        borderLeft: "1px solid white",
                      }}
                    >
                      {label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component={Paper}
                    elevation={5}
                    style={{
                      backgroundColor: primaryBlue,
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      borderRadius: 0,
                    }}
                  />
                  {Array.from({ length: storeGroupMetrics.length }).map(
                    (_, i) => (
                      <TableCell
                        key={i}
                        style={{
                          backgroundColor: primaryBlue,
                        }}
                      />
                    )
                  )}
                </TableRow>
                {individualStoreMetrics.map(
                  (
                    {
                      storeNumber,
                      storeDisplayName,
                      storeIsOpen,
                      storeMetrics,
                    },
                    key
                  ) => {
                    return (
                      <TableRow key={key}>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                            padding: 5,
                          }}
                        >
                          <Box style={{ width: 100 }}>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FiberManualRecordIcon
                                style={{
                                  fontSize: "1.5vh",
                                  marginRight: "5px",
                                  color: isCurrentDay
                                    ? storeIsOpen
                                      ? primaryBlue
                                      : "#c52134"
                                    : "grey",
                                }}
                              />
                              <Typography
                                fontSize={12}
                                fontWeight={"bold"}
                                color={primaryBlue}
                              >
                                {storeNumber}
                              </Typography>
                            </Box>
                            <Typography noWrap fontSize={12}>
                              {storeDisplayName}
                            </Typography>
                          </Box>
                        </TableCell>
                        {storeMetrics.map(({ value, color }, i) => (
                          <TableCell
                            key={i}
                            style={{
                              zIndex: 0,
                              minWidth: 80,
                              textAlign: "center",
                              color,
                              backgroundColor: key % 2 ? "white" : "#e2e2e2",
                            }}
                          >
                            {value}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  }
                )}
                <TableRow>
                  <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      fontWeight: "bold",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography fontWeight={"bold"}>Totals</Typography>
                    <Typography
                      fontSize={12}
                    >{`${totalStores} Stores`}</Typography>
                  </TableCell>
                  {storeGroupMetrics.map(({ value, color }, i) => (
                    <TableCell
                      key={i}
                      style={{
                        zIndex: 0,
                        minWidth: 80,
                        textAlign: "center",
                        fontWeight: "bold",
                        color,
                        borderLeft: "1px solid white",
                      }}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Drawer>
    </>
  );
};

export default StatsStoreGroupCard;
