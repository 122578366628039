import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  IconButton,
  Typography,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { access } from "../../appAccess";
import { primaryBlue } from "../../style";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import axios from "axios";
import UpdateIcon from "@mui/icons-material/Update";
import StatsStoreGroupCard from "./stats-store-group-card";
import Swal from "sweetalert2";

export const STATS_PAGE_MAX_WIDTH = 1200;

const StatsMain = ({ logOut = () => null }) => {
  const [state, setState] = useState({
    isReady: false,
    isLoading: false,
    selectedDate: dayjs().add(0, "day"),
    reportGroupingId: 1,
    lastRefresh: null,
    data: null,
  });

  useEffect(() => fetchData(), []);

  const reportGroupingLocalStorageActions = (() => {
    const THE_KEY = "report-grouping-id";
    return {
      get: () => parseInt(localStorage.getItem(THE_KEY)),
      set: (id) => localStorage.setItem(THE_KEY, id),
    };
  })();

  const fetchData = async (_selectedDate = null) => {
    if (localStorage.getItem("access") !== access) {
      logOut();
      return;
    }

    if (state.isLoading) return;

    setState((prev) => ({ ...prev, isLoading: true }));

    let selectedDate = _selectedDate || state.selectedDate;

    let reportGroupingId =
      reportGroupingLocalStorageActions.get() ?? state.reportGroupingId;

    const res = (
      await axios.post(
        "https://venomwebservice.azurewebsites.net/api/SalesReportingMobile/GetData",
        {
          requestDate: selectedDate.format("MM/DD/YYYY"),
          reportGroupingId,
        }
      )
    )?.data?.[0];

    const isReady = Boolean(res);

    let data;

    if (isReady) {
      data = {
        ...res,
        reportGroupingOptions: JSON.parse(res.reportGroupingOptions),
        storeGroupData: JSON.parse(res.storeGroupData),
      };
      reportGroupingId = data.reportGroupingId;
      selectedDate = dayjs(data.reportDate);
      reportGroupingLocalStorageActions.set(reportGroupingId);
    }

    setState((prev) => ({
      ...prev,
      isReady,
      isLoading: false,
      selectedDate,
      reportGroupingId,
      lastRefresh: isReady ? dayjs() : prev.lastRefresh,
      data,
    }));

    if (!isReady) {
      Swal.fire({
        title: "Error Fetching Data",
        text: `Please try refreshing your browser.`,
        icon: "error",
      });
    }
  };

  return (
    <>
      <Grid
        container
        component={Paper}
        elevation={3}
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#f1f3f4",
          borderRadius: 0,
        }}
      >
        <Box
          style={{
            width: "100%",
            maxWidth: STATS_PAGE_MAX_WIDTH,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Grid container>
            <Grid item xs={10} style={{ cursor: "pointer" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={"Date"}
                  inputFormat="MM/DD/YYYY"
                  value={state.selectedDate}
                  onChange={(selectedDate) => {
                    setState((prev) => ({ ...prev, selectedDate }));
                    fetchData(selectedDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{
                        backgroundColor: "#fff",
                        maxWidth: 175,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Box
                style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              >
                <Typography
                  style={{ marginRight: 5, fontSize: 12 }}
                >{`Last Refresh: `}</Typography>

                {state.isLoading && (
                  <Skeleton variant="rectangle" width={200} height={20} />
                )}
                {!state.isLoading && (
                  <Typography style={{ fontSize: 12 }}>
                    {dayjs(state.lastRefresh).format(
                      "ddd, MMM D, YYYY @ h:mm:ss A"
                    )}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton
                onClick={() => {
                  const selectedDate = dayjs();
                  setState((prev) => ({ ...prev, selectedDate }));
                  fetchData(selectedDate);
                }}
              >
                <UpdateIcon
                  style={{
                    fontSize: 10,
                    backgroundColor: "#03a66e",
                    color: "white",
                    borderRadius: 5,
                    height: 50,
                    width: 50,
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {!state.isReady && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {state.isReady && (
        <>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid
              container
              style={{
                width: "100%",
                maxWidth: STATS_PAGE_MAX_WIDTH,
                padding: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {state.data.storeGroupData
                .filter((i) => !i.reportGroupingId)
                .map((item, i) => (
                  <StatsStoreGroupCard
                    key={i}
                    isLoading={state.isLoading}
                    selectedDate={state.selectedDate}
                    {...state.data}
                    {...item}
                  />
                ))}
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: primaryBlue,
            }}
          >
            <Grid
              container
              style={{
                width: "100%",
                maxWidth: STATS_PAGE_MAX_WIDTH,
                padding: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FormControl>
                <Select
                  value={state.reportGroupingId}
                  onChange={(e) => {
                    const reportGroupingId = e.target.value;

                    reportGroupingLocalStorageActions.set(reportGroupingId);
                    setState((prev) => ({
                      ...prev,
                      reportGroupingId,
                    }));
                    fetchData();
                  }}
                  size="small"
                  autoWidth
                  style={{
                    minWidth: 125,
                    color: "white",
                    border: "1px solid white",
                  }}
                >
                  {state.data.reportGroupingOptions.map(
                    ({ reportGroupingId, reportGroupingLabel }, key) => (
                      <MenuItem key={key} value={reportGroupingId}>
                        {reportGroupingLabel}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: 100,
            }}
          >
            <Grid
              container
              style={{
                width: "100%",
                maxWidth: STATS_PAGE_MAX_WIDTH,
                padding: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {state.data.storeGroupData
                .filter((i) => i.reportGroupingId)
                .map((item, i) => (
                  <StatsStoreGroupCard
                    key={i}
                    isLoading={state.isLoading}
                    selectedDate={state.selectedDate}
                    {...state.data}
                    {...item}
                  />
                ))}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default StatsMain;
