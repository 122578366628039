import { useState } from "react";
import axios from "axios";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import VyperDatePicker from "./vyper-date-picker";
import dayjs from "dayjs";
import VyperAppointmentTypeSection from "./vyper-appt-type-section";
import VyperApptsAccordion from "./vyper-appts-accordion";

const VyperMain = () => {
  const [state, setState] = useState({
    isLoading: false,
    startDate: null,
    endDate: null,
    vyperData: [],
  });

  const handleDateSelection = async ({ startDate, endDate }) => {
    if (!state.isLoading) {
      setState((prev) => ({ ...prev, isLoading: true }));

      const { data = [] } = await axios.post(
        `https://venomwebservice.azurewebsites.net/api/VenomReporting/GetVyperPerformanceData`,
        {
          startDate,
          endDate,
        }
      );

      const vyperData = data.map((i) => ({
        ...i,
        ...Object.assign(
          {},
          ...[
            "totalAppointments",
            "newCustomerAppointments",
            "returningCustomerAppointments",
            "appointmentsBySource",
            "appointmentsByStore",
          ].map((key) => ({ [key]: JSON.parse(i[key]) }))
        ),
      }));

      setState((prev) => ({
        ...prev,
        isLoading: false,
        vyperData,
        startDate,
        endDate,
      }));
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper
            square
            sx={{
              paddingTop: 3,
              paddingBottom: 3,
              padding: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Paper
              sx={{
                backgroundColor: "#cccccc",
                width: 50,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 3,
              }}
            >
              <StackedLineChartIcon />
            </Paper>
            <Typography variant="h5">VYPER</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container sx={{ backgroundColor: "#f4f4f4" }}>
        {state.isLoading && <div>Loading...</div>}
        <Grid item xs={12}>
          <Box sx={{ maxWidth: 500 }}>
            <VyperDatePicker handleDateSelection={handleDateSelection} />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ padding: 1, marginTop: 1, marginBottom: 2 }}
        spacing={2}
      >
        {state.vyperData.map((vyper) => {
          const {
            promotionId,
            promotionName,
            promoStartDate,
            promoEndDate,
            uniqueClientCount,
            appointmentsByStore,
            appointmentsBySource,
          } = vyper;

          const reportingDayCount =
            dayjs(state.endDate).diff(dayjs(state.startDate), "day") + 1;

          return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={promotionId}>
              <Paper
                sx={{
                  padding: 1,
                  backgroundColor: "#f4f4f4",
                  border: "1px solid grey",
                }}
                elevation={7}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  {promotionName}
                </Typography>
                <Typography variant="caption">
                  {`Start Date: ${dayjs(promoStartDate).format(
                    "MMM D, YYYY"
                  )} | Last Date: ${dayjs(promoEndDate).format("MMM D, YYYY")}`}
                </Typography>
                <Box
                  sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                    textAlign: "center",
                    backgroundColor: "white",
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <Typography variant="caption">Reporting Dates: </Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {dayjs(state.startDate).format("MMM D, YYYY")}
                    {` to `}
                    {dayjs(state.endDate).format("MMM D, YYYY")}
                  </Typography>
                  <Typography>{`${reportingDayCount} Promotion day${
                    reportingDayCount > 1 ? "s" : ""
                  }`}</Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    variant="h6"
                    sx={{ paddingTop: 1, paddingBottom: 1 }}
                  >
                    Appointments Summary
                  </Typography>
                  <VyperAppointmentTypeSection {...vyper} />
                </Box>
                {Boolean(appointmentsByStore.length) && (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="h6"
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                      Appointments By Store
                    </Typography>
                    <Grid container>
                      {appointmentsByStore.map((storeAppts, i) => (
                        <VyperApptsAccordion
                          key={i}
                          label={`${storeAppts.storeNumber} ${storeAppts.storeDisplayName}`}
                          {...storeAppts}
                        />
                      ))}
                    </Grid>
                  </Box>
                )}

                {Boolean(appointmentsBySource.length) && (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="h6"
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                      Traffic Sources
                    </Typography>
                    <Grid container>
                      <Box sx={{ display: "flex", marginBottom: 2 }}>
                        <Typography>Unique Visitor Count:</Typography>
                        <Typography sx={{ marginLeft: 1, fontWeight: 600 }}>
                          {uniqueClientCount}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          height: 10,
                          backgroundColor: "grey",
                        }}
                      />
                      <Paper
                        square
                        sx={{
                          width: "100%",
                          marginBottom: 0.5,
                          paddingX: 2,
                          paddingY: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {["Source", "Visitors", "Appts"].map((label) => {
                          return (
                            <Typography key={label} sx={{ fontSize: 14 }}>
                              {label}
                            </Typography>
                          );
                        })}
                      </Paper>

                      {appointmentsBySource.map((sourceAppts, i) => (
                        <VyperApptsAccordion
                          key={i}
                          label={sourceAppts.source}
                          {...sourceAppts}
                        />
                      ))}
                    </Grid>
                  </Box>
                )}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <Backdrop open={state.isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default VyperMain;
