import { useState } from "react";
import { Grid, Typography, Paper, Button, Box } from "@mui/material";
import VyperApptDetails from "./vyper-appt-details";

const VyperAppointmentTypeSection = ({
  totalAppointments,
  newCustomerAppointments,
  returningCustomerAppointments,
}) => {
  const [viewIndex, setViewIndex] = useState(0);

  const sections = [
    [totalAppointments, "Total Appts"],
    [newCustomerAppointments, "New Cust"],
    [returningCustomerAppointments, "Return Cust"],
  ];

  return (
    <Grid container>
      {sections.map(([{ totalCount }, label], i) => {
        const isSelected = i === viewIndex;
        return (
          <Grid item xs={4} sx={{ padding: 1 }} key={i}>
            <Paper
              onClick={() => setViewIndex(i)}
              component={Button}
              fullWidth
              sx={{
                backgroundColor: "white",
                padding: 1,
                border: `${isSelected ? 2 : 0}px solid #1e376c`,
                display: "block",
                textAlign: "left",
                lineHeight: 1,
                textTransform: "none",
              }}
            >
              <Typography variant="caption">{label}</Typography>
              <Typography sx={{ fontWeight: 600 }}>{totalCount}</Typography>
            </Paper>
          </Grid>
        );
      })}
      <VyperApptDetails {...sections[viewIndex][0]} />
    </Grid>
  );
};

export default VyperAppointmentTypeSection;
