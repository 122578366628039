import { Box, CircularProgress } from "@mui/material";
import Login from "./Components/Login";
import { Switch, Route, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { access } from "./appAccess.js";
import HeaderBar from "./Components/HeaderBar";
import VyperMain from "./Components/vyper/vyper-main";
import StatsMain from "./Components/stats/stats-main.js";

function App(props) {
  const [state, setState] = useState({
    isLoggedIn: false,
    isReady: false,
  });

  useEffect(() => {
    let pw = localStorage.getItem("access");
    let isLoggedIn = pw === access;
    setState((prevState) => {
      return {
        ...prevState,
        isLoggedIn,
        isReady: true,
      };
    });
  }, []);

  const setLoggedIn = () => {
    setState((prevState) => {
      return {
        ...prevState,
        isLoggedIn: true,
      };
    });
  };

  const logOut = () => {
    localStorage.setItem("access", "");
    setState((prevState) => {
      return {
        ...prevState,
        isLoggedIn: false,
      };
    });
  };

  return (
    <>
      {!state.isReady && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {state.isReady && (
        <>
          {!state.isLoggedIn && <Login setLoggedIn={setLoggedIn} />}
          {state.isLoggedIn && (
            <>
              <HeaderBar logOut={logOut} />
              <Switch>
                <Route
                  path="/"
                  exact
                  component={() => <StatsMain logOut={logOut} />}
                />
                <Route path="/vyper" exact component={VyperMain} />
              </Switch>
            </>
          )}
        </>
      )}
    </>
  );
}

export default withRouter(App);
