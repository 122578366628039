import { Grid, Box, Typography } from "@mui/material";

const VyperApptDetails = ({
  completedCount,
  revenue,
  pendingCount,
  missedCount,
  newCustomerCount,
  returningCustomerCount,
}) => {
  return (
    <Grid container>
      {[
        ["Completed", completedCount],
        ["Revenue", `$${revenue.toFixed(2)}`],
        ["Pending", pendingCount],
        ["Missed", missedCount],
        ["New Cust", newCustomerCount],
        ["Return Cust", returningCustomerCount],
      ].map(([label, value]) => {
        return (
          <Grid item xs={6} sx={{ padding: 1 }} key={label}>
            <Box
              sx={{
                backgroundColor: "white",
                padding: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="caption">{label}:</Typography>
              <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
                {value}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VyperApptDetails;
