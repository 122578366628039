import { Grid, TextField, Button, Typography } from "@mui/material";
import { primaryBlue } from "../style";
import bbt_logo from "../Images/bbt_logo.png";
import { useState } from "react";
import { access } from "../appAccess";

const Login = ({ setLoggedIn }) => {
  const [state, setState] = useState({
    pw: "",
    isError: false,
  });

  const handleChange = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        pw: e.target.value,
        isError: false,
      };
    });
  };

  const handleSubmit = () => {
    if (state.pw.toLowerCase() === access) {
      localStorage.setItem("access", access.toLowerCase());
      setLoggedIn();
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          isError: true,
          pw: "",
        };
      });
    }
  };

  return (
    <Grid
      container
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: primaryBlue,
        paddingBottom: "15vh",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <Grid item xs={8} sm={6} md={6} lg={3} xl={2}>
        <div
          style={{ width: "100%", marginBottom: "1.5vh" }}
          className="d-flex justify-content-center"
        >
          <img alt="logo" src={bbt_logo} style={{ width: "80%" }} />
        </div>
        <div
          style={{ width: "100%", marginBottom: "3vh" }}
          className="d-flex justify-content-center"
        >
          <Typography style={{ color: "white", fontFamily: "fieldwork" }}>
            Real-time Sales Reporting
          </Typography>
        </div>

        <TextField
          id="outlined-basic"
          label="Password"
          type="password"
          variant="outlined"
          value={state.pw}
          error={state.isError}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
          fullWidth
        />
        {state.isError && (
          <div style={{ color: "red", marginTop: ".5vh" }}>
            Incorrect Password
          </div>
        )}
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          style={{ marginTop: "3vh" }}
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
};

export default Login;
