import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, Grid, Button } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const VyperDatePicker = ({ handleDateSelection }) => {
  const currentDate = dayjs();

  const [state, setState] = useState({
    startDate: currentDate,
    endDate: currentDate,
    hasError: false,
  });

  const createChangeHandler = (key) => (newValue) =>
    setState((prev) => ({ ...prev, [key]: newValue }));

  const submitDates = () => {
    const [startDate, endDate] = [state.startDate, state.endDate].map((item) =>
      item.format("MM/DD/YYYY")
    );

    handleDateSelection({ startDate, endDate });
  };

  useEffect(() => {
    submitDates();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      sx={{ paddingLeft: 1, paddingRight: 1, marginTop: 2 }}
    >
      {[
        ["Start Date", "startDate"],
        ["End Date", "endDate"],
      ].map(([label, key]) => {
        return (
          <Grid item xs={6} key={key}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={label}
                inputFormat="MM/DD/YYYY"
                value={state[key]}
                onChange={createChangeHandler(key)}
                renderInput={(params) => (
                  <TextField {...params} sx={{ backgroundColor: "#fff" }} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        );
      })}

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          color="success"
          onClick={submitDates}
          disabled={state.endDate.diff(state.startDate) < 0}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

export default VyperDatePicker;
