import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  IconButton,
  AppBar,
  Drawer,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { primaryBlue } from "../style";
import bbt_logo from "../Images/bbt_logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

export const HEADER_BAR_HEIGHT = 8;

const offsetModalTop = { sx: { top: HEADER_BAR_HEIGHT * 8 } };

const HeaderBar = ({ logOut }) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const setClosed = () => setIsOpen(false);

  const handleMenuClick = (route) => {
    setClosed();
    history.push(route);
  };

  return (
    <>
      <AppBar
        style={{
          backgroundColor: primaryBlue,
          paddingTop: ".5vh",
          paddingBottom: ".5vh",
          borderRadius: 0,
          position: "sticky",
          zIndex: 3,
          height: `${HEADER_BAR_HEIGHT}vh`,
        }}
      >
        <Grid
          container
          className="d-flex align-items-center"
          style={{ height: "100%" }}
        >
          <Grid item xs={2} sx={{ paddingLeft: 1 }}>
            <IconButton onClick={() => setIsOpen(true)}>
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={8}
            className="d-flex justify-content-center align-items-center"
            style={{ height: "5vh" }}
            onClick={() => history.push("/")}
          >
            <img alt="logo" src={bbt_logo} style={{ height: "80%" }} />
          </Grid>
          <Grid
            item
            xs={2}
            className="d-flex"
            style={{ justifyContent: "end" }}
          >
            <IconButton onClick={logOut}>
              <ExitToAppIcon
                style={{
                  fontSize: 3,
                  color: "white",
                  borderRadius: 5,
                  height: "3.5vh",
                  width: "3.5vh",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={setClosed}
        ModalProps={offsetModalTop}
        BackdropProps={offsetModalTop}
        PaperProps={{
          id: "locationDrawer",
          sx: {
            ...offsetModalTop.sx,
            width: "90%",
            maxWidth: "500px",
            padding: 2,
            backgroundColor: "#efefef",
          },
        }}
      >
        <Paper>
          <MenuList style={{ padding: 0 }}>
            {[
              ["Home", "/"],
              ["Vyper", "/vyper"],
            ].map(([label, route]) => {
              return (
                <MenuItem
                  key={label}
                  style={{ borderBottom: `1px solid grey` }}
                  onClick={() => handleMenuClick(route)}
                >
                  <ListItemText>
                    <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                      {label}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon style={{ justifyContent: "flex-end" }}>
                    <ArrowForwardIosRoundedIcon sx={{ fontSize: 15 }} />
                  </ListItemIcon>
                </MenuItem>
              );
            })}
          </MenuList>
        </Paper>
      </Drawer>
    </>
  );
};

export default HeaderBar;
