// $primary: #1e376c;
// $mainTable: #dfe4ec;
// $darkTableRow: #ebecee;

// $rightSideBarCanvas: #fafafa;

// $canvas: #fafafa;
// $alert: #810505;
// $bbt-grey: grey;

// .bg-primary {
//   background-color: $primary;
// }

export const primaryBlue = "#1e376c";
