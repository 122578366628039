import { useState } from "react";
import { Button, Grid, Paper, Typography, Box, Collapse } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import VyperApptDetails from "./vyper-appt-details";

const VyperApptsAccordion = ({ label, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { totalCount } = props;
  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: "2px" }}>
        <Paper
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            padding: 1,
            textAlign: "left",
            textTransform: "none",
            borderRadius: 0,
          }}
          component={Button}
          fullWidth
        >
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={props?.uniqueClientCount ? 4 : 8}>
              <Typography sx={{ fontSize: 11, color: "#1e376c" }}>
                {label}
              </Typography>
            </Grid>
            {props?.uniqueClientCount && (
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ fontSize: 13, color: "#1e376c" }}>
                  {props?.uniqueClientCount}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                  {totalCount}
                </Typography>
                {isOpen ? (
                  <ExpandLessRoundedIcon sx={{ marginLeft: 1, fontSize: 14 }} />
                ) : (
                  <ExpandMoreRoundedIcon sx={{ marginLeft: 1, fontSize: 14 }} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Collapse in={isOpen}>
          <VyperApptDetails {...props} />
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default VyperApptsAccordion;
